body {
  margin: 0;
  padding: 0;
  direction: rtl;
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, sans-serif;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

:root {
  --primary-color: #1976d2;
  --secondary-color: #dc004e;
  --success-color: #4caf50;
  --error-color: #f44336;
}